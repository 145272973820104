import * as React from "react"
import Feature from "./feature"

import * as styles from "./features.module.scss"

//
// TODO:
// Add more icons
//
const Features = () => (
  <section id='services' className={styles.features_block}>
    <header>
      <h2>Our services</h2>
      <h5>Discover how can we help your business</h5>
    </header>
    <div>
      <Feature
        icon="management"
        image="0"
        title="Management consultancy"
        content="This consists of assistance with the negotiation process
        with foreign partners and clients, organisation as well as support
        for a full range of project works (structural decomposition, work
        schedules, estimates, value of work done, communication plans,
        quality assurance, risk management."
      />
      <Feature
        icon="energy"
        image="1"
        title="Energy projects"
        content="Using substantial amount of data modelling, analytical
        work and understanding the practical adaptation of any changes,
        we are able to help our clients make best decisions possible in 
        order to reduce their costs, boosts their performance and maximise
        their income.  We are here to assist our counterparties contacting
        potential buyers and sellers to arrange transactions. In addition
        to the above we advise on environmental assessment as well as on
        finding ways to optimise carbon footprint and improve energy
        efficiency."
      />
      <Feature
        icon="logistics"
        image="2"
        title="Logistics and transportation"
        content="Our vast experience in the field lets us give our clients
        best possible advices on transportation strategy development,
        commodity transportation, stock control, warehousing. We help our
        counterparties to undertake technical changes in-house as well as
        to outsource operations to service providers. Using evidence-based
        methodology we provide assistance that is invaluable to make decisions
        that are financially and operationally optimal."
      />
      <Feature
        icon="risks"
        image="3"
        title="Cost control and risk management"
        content="We provide different kind of services that help our clients
        analyse their risk exposure as well as apply control measures to those
        exposures for the purposes of the quantitative decrease of risk. Taking
        an approach that pairing and looping elements to meet the budget is not
        always effective, we aim to provide advice towards increase in value and
        functionality within cost-controlled environment. "
      />
    </div>
  </section>
)

export default Features
