// extracted by mini-css-extract-plugin
export var icon_clock = "icard-module--icon_clock--5zE0U";
export var icon_consulting = "icard-module--icon_consulting--cLP9J";
export var icon_delivery = "icard-module--icon_delivery--nZyE7";
export var icon_electricity = "icard-module--icon_electricity--7T1mh";
export var icon_email = "icard-module--icon_email--XRQen";
export var icon_globe = "icard-module--icon_globe---jNSL";
export var icon_goal = "icard-module--icon_goal--HuGhw";
export var icon_mission = "icard-module--icon_mission--xlulD";
export var icon_partners = "icard-module--icon_partners--veBKq";
export var icon_phone = "icard-module--icon_phone--xW6bm";
export var icon_security = "icard-module--icon_security--ptisK";
export var icard = "icard-module--icard--zzbT4";