import * as React from 'react'
import BGVideo from '../bgvideo/bgvideo'
import MP4Video from '../../videos/westbournco.mp4'
import WebMVideo from '../../videos/westbournco.webm'
import OgvVideo from '../../videos/westbournco.ogv'
import Poster from '../../images/westbournco-bg.jpg'

import * as styles from './hero.module.scss'

const HeroBlock = () => (
  <section className={styles.hero_block}>
    <div>
      <div className={styles.hero_block__welcome}>
        <h1>Opportunities multiply as they are seized</h1>
        <p>
          We provide a wide range of consulting services to
          improve the decision making, logistics and boost 
          overall performance of your business.
        </p>
      </div>
    </div>
    <BGVideo
      poster={Poster}
      videoMp4={MP4Video}
      videoOgg={OgvVideo}
      videoWebm={WebMVideo}
    />
  </section>
)

export default HeroBlock
