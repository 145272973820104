import * as React from 'react'

import * as styles from './feature.module.scss'

const getBackgroundImage = (image) => {
  switch(image) {
    case '0':
      return `${styles.feature_img_0}`
    case '1':
      return `${styles.feature_img_1}`
    case '2':
      return `${styles.feature_img_2}`
    case '3':
      return `${styles.feature_img_3}`
    default:
      return ''
  }
}

const getFeatureIcon = (icon) => {
  switch (icon) {
    case 'management':
      return styles.icon_consulting
    case 'energy':
      return styles.icon_electricity
    case 'logistics':
      return styles.icon_delivery
    case 'risks':
      return styles.icon_security
    default:
      return ''
  }
}

const Feature = ({ title, content, icon, image }) => (
  <div className={`${styles.feature} ${getBackgroundImage(image)}`}>
    <div className={styles.feature__details}>
      <div>
        <div>
          <span className={getFeatureIcon(icon)}></span>
        </div>
        <div>
          <h4>{title}</h4>
        </div>
      </div>
      <p>
        {content}
      </p>
    </div>
    <div className={styles.feature__headline}>
      <div>
        <div>
          <span className={getFeatureIcon(icon)}></span>
        </div>
        <div>
          <h4>{title}</h4>
        </div>
      </div>
    </div>
  </div>
)

export default Feature
