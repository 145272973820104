import * as React from "react"
import Layout from "../components/layout/layout"
import HeroBlock from "../components/hero/hero"
import AboutBlock from "../components/about/about"
import FeaturesBlock from "../components/features/features"
import ContactsBlock from "../components/contacts/contacts"
import BannersBlock from "../components/banners/banners"
import FormContacts from '../components/formContacts/formContacts'
import Seo from "../components/seo/seo"

import "../styles/index.module.scss"

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Welcome" />
      <main>
        <HeroBlock />
        <AboutBlock />
        <BannersBlock />
        <FeaturesBlock />
        <ContactsBlock />
        <FormContacts />
      </main>
    </Layout>
  )
}

export default IndexPage
