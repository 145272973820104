import * as React from 'react'
import Banner from './banner'

import * as styles from './banners.module.scss'

const Banners = () => (
  <section className={styles.banners_block}>
    <div>
      <Banner
        icon='goal'
        styling='orange'
        title='Our Goals'
        content='We focus on becoming internationally recognised service
        provider with extensive range of clients and diverse portfolio.
        We implement our skills and knowledge to accomplish an optimal
        return on utilized financial capital whilst operating on a
        sustainable win-win basis with our counterparties and
        service providers.'
      />
      <Banner
        icon='mission'
        styling='gray'
        title='Our Mission'
        content='We strive to provide the superior quality professional
        services to our clients. Our main aim is to find the most
        cost-effective method on every stage of the service provision
        whilst implementing the highest standard of business ethics.'
      />
      <Banner
        icon='globe'
        styling='dark'
        title='Our Geography'
        content='Our primary focus is on the UAE/Gulf Countries, Kazakhstan,
        Azerbaijan, Mongolia as well as on the rest of the Asian market.'
      />
    </div>
  </section>
)

export default Banners
