import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './about.module.scss'

const About = () => (
  <section id='about' className={styles.about_block}>
    <div>
      <header>
        <h2>Welcome to <span>Westbourn</span></h2>
        <h5>Our company background</h5>
      </header>
      <main>
        <div>
          <p>
            Established in 2021, WESTBOURNE CO DMCC has it’s headquarter
            located in the Jumeirah Lake Towers area in Dubai, United Arab Emirates.
            Our prime activity is management consultancy, including energy projects,
            logistics, transport, cost control, risk management services.
          </p>
          <p>
            Despite the fact of recent incorporation, our key employees and top
            management have two decades of experience and in-depth knowledge in
            consultancy and advisory business.
          </p>
          {/* <ul>
            <li>Point 1</li>
            <li>Point 2</li>
            <li>Point 3</li>
            <li>Point 4</li>
          </ul> */}
        </div>
        <div>
          <StaticImage
            src="../../images/westbournco-about.jpg"
            alt=""
            placeholder="blurred"
          />
        </div>
      </main>
    </div>
  </section>
)

export default About
