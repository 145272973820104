import * as React from 'react'

import * as styles from './icard.module.scss'

const getCardIcon = (icon) => {
  switch (icon) {
    case 'phone':
      return styles.icon_phone
    case 'clock':
      return styles.icon_clock
    case 'email':
      return styles.icon_email
    case 'globe':
      return styles.icon_globe
    default:
      return ''
  }
}

const ICard = ({ title, icon, children }) => (
  <div className={styles.icard}>
    <div>
      <span className={getCardIcon(icon)}></span>
    </div>
    <div>
      <h5>{title}</h5>
      <h4>{children}</h4>
    </div>
  </div>
)

export default ICard
