// extracted by mini-css-extract-plugin
export var icon_clock = "feature-module--icon_clock--1JfJg";
export var icon_consulting = "feature-module--icon_consulting--Iwu6p";
export var icon_delivery = "feature-module--icon_delivery--375kh";
export var icon_electricity = "feature-module--icon_electricity--vqk1C";
export var icon_email = "feature-module--icon_email--M1bdk";
export var icon_globe = "feature-module--icon_globe--EY3xj";
export var icon_goal = "feature-module--icon_goal--AP8Ig";
export var icon_mission = "feature-module--icon_mission--u6IAi";
export var icon_partners = "feature-module--icon_partners--939e7";
export var icon_phone = "feature-module--icon_phone--HOJj6";
export var icon_security = "feature-module--icon_security--o6S2C";
export var feature = "feature-module--feature--JVks9";
export var feature__details = "feature-module--feature__details--OfGwN";
export var feature__headline = "feature-module--feature__headline--KGcDq";
export var feature_img_0 = "feature-module--feature_img_0--5QiqY";
export var feature_img_1 = "feature-module--feature_img_1--6LIoI";
export var feature_img_2 = "feature-module--feature_img_2--wGiXi";
export var feature_img_3 = "feature-module--feature_img_3--NNgch";