import * as React from 'react'
import VideoBg from 'reactjs-videobg'

import * as styles from './bgvideo.module.scss'

const BGVideo = ({ poster, videoOgg, videoWebm, videoMp4 }) => (
  <VideoBg
    muted={true}
    poster={poster}
    videoClass={styles.bgvideo_block}>
      <VideoBg.Source src={videoOgg} type="video/ogg" />
      <VideoBg.Source src={videoWebm} type="video/webm" />
      <VideoBg.Source src={videoMp4} type="video/mp4" />
  </VideoBg>
)

export default BGVideo
