// extracted by mini-css-extract-plugin
export var icon_clock = "banner-module--icon_clock--IoJDV";
export var icon_consulting = "banner-module--icon_consulting--A4agN";
export var icon_delivery = "banner-module--icon_delivery--eJB1M";
export var icon_electricity = "banner-module--icon_electricity--qbqT+";
export var icon_email = "banner-module--icon_email--CQYjp";
export var icon_globe = "banner-module--icon_globe--jOy0J";
export var icon_goal = "banner-module--icon_goal--Ke5JI";
export var icon_mission = "banner-module--icon_mission--R+Fgw";
export var icon_partners = "banner-module--icon_partners--dVG7S";
export var icon_phone = "banner-module--icon_phone--SMzV9";
export var icon_security = "banner-module--icon_security--FCRGi";
export var banner_block = "banner-module--banner_block--K8tMv";
export var banner_block__orange = "banner-module--banner_block__orange--JGXxU";
export var banner_block__gray = "banner-module--banner_block__gray--AiEgC";
export var banner_block__dark = "banner-module--banner_block__dark--QI5Yu";