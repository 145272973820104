import * as React from 'react'
import ICard from '../icard/icard'

import * as styles from './contacts.module.scss'

const Contacts = () => (
  <section className={styles.contacts_block}>
    <div>
      <div>
        <ICard
          icon="email"
          title="Email us"
        >
          <a href="mailto:info@westbournco.com">info@westbournco.com</a>
        </ICard>
      </div>
      <div>
        <ICard
          icon="clock"
          title="Monday to Friday"
        >
          10:00am &ndash; 06:00pm
        </ICard>
      </div>
      <div>
        <ICard
          icon="globe"
          title="Visit us"
        >
          Unit 1403-22, 14th floor,
          Swiss Tower, Cluster Y,
          Jumeirah Lakes Towers,
          Dubai, UAE&nbsp;
          <a
            href='https://goo.gl/maps/VatXumULTpgSX4CbA'
            rel='noreferrer'
            target='_blank'>
            &#x21E2;
          </a>
        </ICard>
      </div>
    </div>
  </section>
)

export default Contacts
