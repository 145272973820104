import * as React from 'react'

import * as styles from './banner.module.scss'

const getBannerIcon = (icon) => {
  switch (icon) {
    case 'mission':
      return styles.icon_mission
    case 'goal':
      return styles.icon_goal
    case 'globe':
      return styles.icon_globe
    default:
      return ''
  }
}

const getBannerStyling = (styling) => {
  switch (styling) {
    case 'dark':
      return `${styles.banner_block} ${styles.banner_block__dark}`
    case 'gray':
      return `${styles.banner_block} ${styles.banner_block__gray}`
    case 'orange':
      return `${styles.banner_block} ${styles.banner_block__orange}`
    default:
      return `${styles.banner_block}`
  }
}

const Banners = ({ title, content, icon, styling }) => (
  <div className={getBannerStyling(styling)}>
    <div>
      <span className={getBannerIcon(icon)}></span>
    </div>
    <div>
      <h3>{title}</h3>
      <p>
        {content}
      </p>
    </div>
  </div>
)

export default Banners
