import * as React from 'react'
import {
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as styles from './formContacts.module.scss'

class FormContacts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false,
      isEnabled: false,
      isCompleted: false,
      hasError: false,
      errorCode: 0,
      errorMessage: '',
      fullName: '',
      message: '',
      email: '',
      phone: '',
      source: 'westbournco',
      apiUrl: '',
    }

    this.hideContactsContainer = this.hideContactsContainer.bind(this)
    this.resetErrorState = this.resetErrorState.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)
  }

  //
  // setAPIUrl() ...
  // Sets API url depending on the env
  //
  setAPIUrl() {
    const host = window.location.hostname

    switch(host) {
      case 'localhost':
        return { host: 'localhost:8080', proto: 'http' }
      case 'www-development.westbournco.com':
        return { host: 'api-development.crm.codedred.com', proto: 'https' }
      default:
        return { host: 'api.crm.codedred.com', proto: 'https' }
    }
  }

  //
  // getInputValue(target) ...
  // Helper function to get value from checkboxes and other inputs
  //
  getInputValue(target) {
    return target.type === 'checkbox' ? target.checked : target.value
  }

  //
  // handleChange(event) ...
  // Handles input values mutations
  //
  handleChange(event) {
    const target = event.target
    const value = this.getInputValue(target)
    const name = target.name

    this.setState({ [name]: value })
  }

  //
  // validateEmail(email) ...
  // Validates input email
  //
  validateEmail(email) {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  }

  //
  // validatePhone(phone) ...
  // Validates input phone
  //
  validatePhone(phone) {
    return phone.length > 7 ? true : false 
  }

  //
  // handleSubmit() ...
  // Handles form submit event and sends requests to API
  //
  handleSubmit(event) {
    event.preventDefault()

    const data = this.state
    const { host, proto } = this.state.apiUrl
    const url = `${proto}://${host}/api/v1/requests/new`

    if ( !this.validateEmail(data.email) ) {
      this.setState({
        isCompleted: false,
        hasError: true,
        errorCode: 0,
        errorMessage: 'Your email is not valid. Please, check email and try again'
      })
      return
    }

    if ( !this.validatePhone(data.phone) ) {
      this.setState({
        isCompleted: false,
        hasError: true,
        errorCode: 0,
        errorMessage: 'Your phone is not valid. Please, check email and try again'
      })
      return
    }

    const req = {
      full_name: data.fullName,
      message: data.message,
      phone: data.phone,
      email: data.email.toLowerCase(),
      source: data.source,
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(req),
    })
      .then( res => {
        switch (res.status) {
          case 201:
            console.log(res.status)
            this.setState({
              isCompleted: true,
              hasError: false,
              errorCode: 0,
              errorMessage: '',
            })
            this.handleSuccess()
            break
          case 400:
              this.setState({
                isCompleted: true,
                hasError: true,
                errorCode: res.status,
                errorMessage: "Bad input. Please, check the form and try again",
              })
              this.handleSuccess()
              break
            case 409:
            this.setState({
              isCompleted: true,
              hasError: true,
              errorCode: res.status,
              errorMessage: "You've already signed up",
            })
            this.handleSuccess()
            break
          default:
            this.setState({
              isCompleted: false,
              hasError: true,
              errorCode: res.status,
              errorMessage: 'Unknown error. Please, try again later'
            })
        }
        return res.json()
      })
      .then(
        (result) => {
          console.log('Result:', result)
        },
        (error) => {
          console.log('Error:', error)
          this.setState({
            hasError: true,
            errorMessage: 'Networking issues. Please, try again later'
          })
        }
      )
  }

  //
  // handleSuccess() ...
  // Handles success submission of a form
  //
  handleSuccess() {
    document.getElementById('main-block').scrollIntoView({
      block: 'center',
      behavior: 'smooth'
    })
  }

  //
  // resetErrorState() ...
  // Resets error state to a no error state
  //
  resetErrorState() {
    this.setState({
      hasError: false,
      errorCode: 0,
      errorMessage: '',
    })
  }

  //
  // hideContainer() ...
  // Resets error state to a no error state
  //
  hideContactsContainer() {
    document.getElementById('contacts-form-block').style.display = 'none'
  }

  //
  // componentDidMount() ...
  // React lifecycle method
  //
  componentDidMount() {
    this.setState({
      apiUrl: this.setAPIUrl()
    })

    this.setState({ isLoaded: true })
  }

  //
  // render() ...
  // Renders component depending on a current state
  //
  render() {

    if (!this.state.isLoaded) {
      return (
        <>
          &nbsp;
        </>
      )
    }

    if (this.state.isCompleted) {
      return (
        <section id='contacts-form-block' className={styles.form_container}>
          <section id='success-block' className={styles.form_block__success}>
            <h2>Success!</h2>
            <p>
              Thank you for your registration. You will get an email from us. Check your mailbox.
            </p>
          </section>
        </section>
      )
    }

    return (
      <section id='contacts-form-block' className={styles.form_container}>
        <span>
          <button onClick={this.hideContactsContainer}>
            <FontAwesomeIcon
              icon={faTimes}
              className="icon-class"
            />
          </button>
        </span>

        <form
          autoComplete='off'
          onSubmit={this.handleSubmit}
          className={styles.form_block}>

          <section className={styles.form_block__error} style={{ display: this.state.hasError ? 'block' : 'none' }}>
            <div>
              <p>
                {this.state.errorMessage}
              </p>
              <button
                type='button'
                className={`${styles.button} ${styles.button__blue}`}
                onClick={this.resetErrorState}>
                  Okay
              </button>
            </div>
          </section>

          <h4>
            Your Name<sup>&#42;</sup>
          </h4>
          <label htmlFor="fieldFullName">
            <input
              type="input"
              id="fieldFullName"
              name="fullName"
              value={this.state.fullName}
              onChange={this.handleChange}
              placeholder="John Doe"
              required
            />
          </label>

          <h4>
            Your Email<sup>&#42;</sup>
          </h4>
          <label htmlFor="fieldEmail">
            <input
              type="email"
              id="fieldEmail"
              name="email"
              value={this.state.email}
              onChange={this.handleChange}
              placeholder="johndoe@example.com"
              required
            />
          </label>

          <h4>
            Your Phone<sup>&#42;</sup>
          </h4>
          <label htmlFor="fieldPhone">
            <input
              type="tel"
              id="fieldPhone"
              name="phone"
              value={this.state.phone}
              onChange={this.handleChange}
              placeholder="+1234567890"
              required
            />
          </label>

          <h4>
            Your Message
          </h4>
          <label htmlFor="fieldMessage">
            <textarea
              type="input"
              id="fieldMessage"
              name="message"
              value={this.state.message}
              onChange={this.handleChange}
              rows={4}
              placeholder="Your message"
            ></textarea>
          </label>

          <h4>
            Accept the statement below
          </h4>
          <label>
            <input
              required
              type="checkbox"
              style={{ display: 'inline-block' }}
            />
            <span>
              By submitting this form I agree for my personal data being processed and stored by Westbourn.
            </span>
          </label>

          <center>
            <button
              id='btn-sign-up'
              action='submit'
              className={`${styles.button} ${styles.button__blue}`}>
              Submit
            </button>
          </center>
        </form>
      </section>
    )
  }
}

export default FormContacts
